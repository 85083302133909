import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import AuthApi from '../api/authApi';
import ConnectionsApi from '../api/connectionsApi';
import DataSourcesApi from '../api/dataSourcesApi';
import UsersApi from '../api/usersApi';
import Header from '../components/Header';
import { API_URL, FRONTEND_URL } from '../config/systemConfig';
import IAuthorizationCodes from '../models/platform/authorizationCodes';
import IConnection from '../models/platform/connection';
import { IDataSource } from '../models/platform/dataSources';
import IUser from '../models/platform/user';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { getCookie, removeCookie, setCookie } from '../utils/cookiesUtils';

interface LocationState {
    data_data_source?: IDataSource;
}

const Connections: React.FC = () => {
    const location = useLocation();
    const { data_data_source } = (location.state || {}) as LocationState;

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const [loading, setLoading] = useState<boolean>(true);
    const [connections, setConnections] = useState<IConnection[]>([]);
    const [user, setUser] = useState<IUser>();

    const user_id = queryParams.user_id as string;
    const data_source = queryParams.data_source as string;
    const integration = queryParams.integration as string;
    const looker_redirect_uri = queryParams.looker_redirect_uri as string;
    const looker_state = queryParams.looker_state as string;

    const [dataSource, setDataSource] = useState<IDataSource | undefined>(data_data_source);

    const handleDoneClick = () => {
        try {
            const authorization_code = getCookie('auth_code');

            if (!authorization_code) {
                console.warn('Authorization code not found.');
                toast.error('Authorization error. Please try again.');
                return;
            }

            if (looker_redirect_uri) {
                removeCookie('auth_code');
                window.location.href = `${looker_redirect_uri}?state=${looker_state}&code=${authorization_code}`;
            } else {
                console.warn('Looker redirect URI not found.');
                toast.error('Failed to redirect. Please try again.');
            }
        }
        catch (error) {
            console.error('JWT Decoding Error:', error);
            toast.error('Authorization error. Please try again.');
            return;
        }
    };

    const handleAddConnectionClick = () => {
        initiateDataSourceOAuth();
    };

    const initiateDataSourceOAuth = async () => {
        try {
            console.log(`${API_URL}/${data_source}/connections/authorize?integration=${integration}&data_source=${data_source}&redirect_uri=${encodeURIComponent(`${FRONTEND_URL}/connections?user_id=${user_id}&data_source=${data_source}&integration=${integration}&looker_redirect_uri=${looker_redirect_uri}&looker_state=${looker_state}`)}`);
    
            // window.location.href = `${API_URL}/${data_source}/connections/authorize?integration=${integration}&data_source=${data_source}&redirect_uri=${encodeURIComponent(`${FRONTEND_URL}/connections?user_id=${user_id}&data_source=${data_source}&integration=${integration}&looker_redirect_uri=${looker_redirect_uri}&looker_state=${looker_state}`)}`;
        }
        catch (error) {
            console.error('Error initiating authorization:', error);
        }
    };

    const createAuthCode = async () => {
        try {
            const response = await AuthApi.createAuthCode();
            if (response.success) {
                const data = (response as SuccessResponse).data as IAuthorizationCodes;
                setCookie('auth_code', data.authorization_code, 1);
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    };

    const getConnections = async () => {
        try {
            const response = await ConnectionsApi.getConnections(data_source);
            if (response.success) {
                const data = (response as SuccessResponse).data as IConnection[];
                setConnections(data);

                if (data.length === 0) {
                    initiateDataSourceOAuth();
                }
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    };

    const getDataSources = async (data_source: string) => {
        try {
            const response = await DataSourcesApi.getDataSources(data_source);
            if (response.success) {
                const data = (response as SuccessResponse).data as IDataSource;
                setDataSource(data);
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    };

    const getUserTeam = async () => {
        try {
            const response = await UsersApi.getUserTeam();
            if (response.success) {
                const data = (response as SuccessResponse).data as IUser;
                setUser(data);
            }
            else {
                toast.error(response.message);
            }
        }
        catch (error: any) {
            toast.error(handleAxiosError(error).message);
        }
    };

    useEffect(() => {
        const fetchConnections = async () => {
            try {
                if (data_data_source === undefined) {
                    await getDataSources(data_source);
                }

                const existing_auth_code = getCookie('auth_code');
                if (!existing_auth_code) {
                    await createAuthCode();
                }

                await getUserTeam();
                await getConnections();
            }
            catch (error) {
                console.error('Error fetching connections:', error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchConnections();
    }, []);

    return (
        <div>
            {loading ? (
                <div className="flex h-screen items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <>
                    <Header iconUrl={dataSource?.logo_url || ''} />
                    <div className="content-container">
                        <h1>Authentication for {data_data_source?.name || ''}</h1>
                        <h2 className="team-description">
                            Current team connections:
                        </h2>
                        {connections.length === 0 ? (
                            <div>
                                <p>No connections available.</p>
                            </div>
                        ) : (
                            <ul style={{ marginTop: '10px' }}>
                                {connections.map((connection) => (
                                    <li key={connection.connection_id} className="connection-list-item">
                                        {connection.name} (Id: {connection.account_id})
                                    </li>
                                ))}
                            </ul>
                        )}
                        {connections.length > 0 && ['owner', 'admin'].includes(user?.teams?.[0]?.role || '') && (
                            <button onClick={() => handleAddConnectionClick()} className="add-connection-button" style={{ marginTop: '10px' }}>
                                + Add Connection
                            </button>
                        )}
                    </div>
                    <footer className="footer-container">
                        <button onClick={() => handleDoneClick()} className="done-button">Done</button>
                    </footer>
                </>
            )}
        </div>
    );
};

export default Connections;
